<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>运营管理</a-breadcrumb-item>
    <a-breadcrumb-item>企微好友消息推送</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 推广 -->
    <span class="tool-title">关键词：</span>
    <a-input v-model:value="search" style="width: 160px;" placeholder="请输入ID或名称" />
    <!-- 创建人 -->
    <span class="tool-title">创建人：</span>
    <a-select v-model:value="creater" style="width: 120px;" placeholder="请选择">
      <a-select-option :value="0">全部</a-select-option>
      <a-select-option
        v-for="item in creaters"
        :key="item.admin_user_id"
        :value="item.admin_user_id"
      >
        {{ item.username }}
      </a-select-option>
    </a-select>
    <!-- 日期： -->
    <span class="tool-title">推送日期：</span>
    <a-date-picker
      v-model:value="dateRange"
      :format="dateFormat"
    />
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="handleSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
    <div style="flex: 1;"></div>
    <!-- 导出 -->
    <a-button style="margin-right: 16px;" @click="touchDownload">
      导出列表
    </a-button>
    <!-- 导出充值明细 -->
    <a-button style="margin-right: 16px;" @click="touchDownloadRechargeDetails">
      导出充值明细
    </a-button>
    <!-- 新建 -->
    <a-button type="primary" @click="touchEdit()">
      <plus-outlined />
      新建
    </a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 消息类型 -->
      <template v-if="column.key === 'message_type'">
        {{ (PushMsgTypes.find(item => item.value === record[column.key]) || {}).text || '-' }}
      </template>
      <!-- 剧名 -->
      <template v-if="column.key === 'project_drama_name'">
        {{ record.project_drama_name || '-' }}
      </template>
      <!-- 推送时间 -->
      <template v-if="column.key === 'send_time'">
        <a-tooltip v-if="record[column.key]">
          <template #title>{{ record[column.key].substring(11, 19) }}</template>
          {{ record[column.key].substring(0, 10) }}
        </a-tooltip>
        <span v-else>{{ '-' }}</span>
      </template>
      <!-- 推送状态 -->
      <template v-if="column.key === 'push_status'">
        {{ (PushMsgStatus.find(item => item.value === record[column.key]) || {}).text || '-' }}
      </template>
      <!-- 金额 -->
      <template v-if="column.key === 'order_amount_count' || column.key === 'yesterday_order_amount_count'">
        {{ $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <!-- 详情 -->
        <a class="operation-item" @click="touchDetail(record)">详情</a>
        <span class="gap">|</span>
        <!-- 更多 -->
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click.prevent>
            更多
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu style="width: 100px;">
              <!-- 编辑 -->
              <a-menu-item :disabled="![1, 3, 4].includes(record.push_status)">
                <a href="javascript:;" @click="touchEdit(record)">编辑</a>
              </a-menu-item>
              <!-- 推送 -->
              <a-menu-item :disabled="![1].includes(record.push_status)">
                <a href="javascript:;" @click="touchPush(record, 2)">推送</a>
              </a-menu-item>
              <!-- 取消推送 -->
              <a-menu-item :disabled="![1].includes(record.push_status)">
                <a href="javascript:;" @click="touchPush(record, 1)">取消推送</a>
              </a-menu-item>
              <!-- 重新推送 -->
              <a-menu-item :disabled="![3, 4].includes(record.push_status)">
                <a href="javascript:;" @click="touchPush(record, 2)">重新推送</a>
              </a-menu-item>
              <!-- 充值明细 -->
              <a-menu-item>
                <a href="javascript:;" @click="touchRechargeDetail(record)">充值明细</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </template>
  </a-table>
  <!-- 新增 -->
  <Add ref="RefAdd" @success="getList"></Add>
  <!-- 充值明细 -->
  <RechargeDetails ref="RefRechargeDetails"></RechargeDetails>
</template>

<script setup>
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { onBeforeMount, createVNode, ref, reactive } from 'vue'
import { message, Modal } from 'ant-design-vue'
import dayjs from 'dayjs'
import Pub from '@/utils/public'
import { PushMsgTypes, PushMsgStatus } from '@/utils/constantList'
import Add from './components-friend-msg/Add.vue'
import RechargeDetails from './components-friend-msg/RechargeDetails.vue'
import { messageFriendList, userAll, messageSendMessage, exportMessage } from '@/api/operate'

import * as Ex from "@netjoy/excelex"
import { RechargeTypes } from '@/utils/constantList'

// 新增、编辑组件实例
let RefAdd = ref(null)
// 充值明细
let RefRechargeDetails = ref(null)
// 加载
let isLoading = ref(false)
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 日期范围
// let dateRange = ref(dayjs())
let dateRange = ref(undefined)
// 搜索
let search = ref(undefined)
// 创建人
let creater = ref(undefined)
// 刷新时间
let lastTime = ref(undefined)
// 创建人列表
let creaters = ref([])
// 数据源
let dataSource = ref([])
// 排序字段
let order = ref('id')
// 排序顺序
let orderType = ref('desc')
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '消息ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    defaultSortOrder: 'descend'
  },
  {
    title: '消息类型',
    dataIndex: 'message_type',
    key: 'message_type',
  },
  {
    title: '剧名',
    dataIndex: 'project_drama_name',
    key: 'project_drama_name',
  },
  {
    title: '创建人',
    dataIndex: 'create_username',
    key: 'create_username'
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time'
  },
  {
    title: '推送时间',
    dataIndex: 'send_time',
    key: 'send_time'
  },
  {
    // 1-带推送  2-已推送  3-取消推送  4-推送失败'
    title: '推送状态',
    dataIndex: 'push_status',
    key: 'push_status'
  },
  {
    title: '推送数',
    dataIndex: 'send_num',
    key: 'send_num'
  },
  {
    title: '成功数',
    dataIndex: 'send_success_num',
    key: 'send_success_num'
  },
  {
    title: '访问用户',
    dataIndex: 'user_log_count',
    key: 'user_log_count',
  },
  {
    title: '充值用户',
    dataIndex: 'order_user_count',
    key: 'order_user_count',
  },
  {
    title: '累计充值',
    dataIndex: 'order_amount_count',
    key: 'order_amount_count',
    // sorter: true,
    // sortDirections: ['descend', 'ascend']
  },
  {
    title: '昨日充值',
    dataIndex: 'yesterday_order_amount_count',
    key: 'yesterday_order_amount_count'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取用户列表
  getUserAll()
  // 获取列表
  getList()
})

// 详情（不可编辑）
function touchDetail (record) {
  RefAdd.value.showDrawer(record, true)
}

// 新建/编辑
function touchEdit (record) {
  RefAdd.value.showDrawer(record || {})
}

// 查询
function handleSearch () {
  // 设置分页
  pagination.current = 1
  // 获取列表
  getList()
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  order.value = ''
  orderType.value = ''
  if (sorter.columnKey) {
    order.value = sorter.columnKey
    orderType.value = sorter.order === 'descend' ? 'desc' : 'asc'
  }
  getList()
}

// 重置
function handleReset () {
  // 筛选项重置
  dateRange.value = undefined // dayjs()
  search.value = undefined
  creater.value = undefined
  // 分页重置
  pagination.current = 1
  getList()
}

// 刷新接口
function refreshData () {
  popularizeRefreshData().then(res => {
    const { code, data } = res
    if (code === 0) {
      lastTime.value = data.last_time
      message.success('更新成功')
      handleSearch()
    }
  })
}

// 充值明细
function touchRechargeDetail (record) {
  RefRechargeDetails.value.showDrawer(record)
}

// 创建人列表
function getUserAll () {
  userAll().then(res => {
    const { code, data } = res
    if (code === 0) {
      creaters.value = data
    }
  })
}

// 获取列表
function getList () {
  isLoading.value = true
  const params = {
    date: dateRange.value ? `${dateRange.value.format(dateFormat)}` : undefined,
    kw: search.value,
    create_user_id: creater.value,
    order: order.value,
    order_type: orderType.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  messageFriendList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  }).catch(() => {
    isLoading.value = false
  })
}

// 推送操作
function touchPush (record, type) {
  if (type === 1) {
    // 取消推送
    Modal.confirm({
      title: '确定要取消推送吗？',
      icon: createVNode(ExclamationCircleOutlined),
      onOk() {
        handlePush(record, type)
      }
    })
  } else {
    // 重新推送
    Modal.confirm({
      title: '确定要重新推送吗？',
      icon: createVNode(ExclamationCircleOutlined),
      onOk() {
        handlePush(record, type)
      }
    })
  }
  
}
function handlePush (record, type) {
  isLoading.value = true
  const params = {
    id: record.id,
    type: type
  }
  messageSendMessage(params).then(res => {
    isLoading.value = false
    const { code, msg } = res
    if (code === 0) {
      message.success(type === 1 ? '取消成功' : '推送成功')
      getList()
    } else {
      message.error(res.message || msg)
    }
  }).catch(() => {
    isLoading.value = false
  })
}

// 导出数据
function touchDownload () {
  isLoading.value = true
  const params = {
    date: dateRange.value ? `${dateRange.value.format(dateFormat)}` : undefined,
    kw: search.value,
    create_user_id: creater.value,
    order: order.value,
    order_type: orderType.value,
    page: pagination.current,
    page_size: 20000
  }
  messageFriendList(params).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0) {
      const list = data.data || []
      if (list.length) {
        const columns = [
          {
            name: '消息ID',
            field: 'id'
          },
          {
            name: '消息类型',
            field: 'message_type'
          },
          {
            name: '剧名',
            field: 'project_drama_name'
          },
          {
            name: '创建人',
            field: 'create_username'
          },
          {
            name: '创建时间',
            field: 'create_time'
          },
          {
            name: '推送时间',
            field: 'send_time'
          },
          {
            name: '推送状态',
            field: 'push_status'
          },
          {
            name: '推送数',
            field: 'send_num'
          },
          {
            name: '成功数',
            field: 'send_success_num'
          },
          {
            name: '访问用户',
            field: 'user_log_count'
          },
          {
            name: '充值用户',
            field: 'order_user_count'
          },
          {
            name: '累计充值',
            field: 'order_amount_count'
          },
          {
            name: '昨日充值',
            field: 'yesterday_order_amount_count'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, (item, field, json, sheetIndex, row, col, rowCount, colCount) => {
          // row=0 为表头
          if (row != 0) {
            // 消息类型处理
            if (field === 'message_type') {
              item.data = (PushMsgTypes.find(item => item.value === json[field]) || {}).text || '-'
              return item
            } else if (field === 'push_status') {
              item.data = (PushMsgStatus.find(type => type.value === json[field]) || {}).text || '-'
              return item
            } else if (field === 'order_amount_count' || field === 'yesterday_order_amount_count') {
              item.data = Pub.KEEP_NUMBER_DECIMAL((json[field] || 0) / 100, 2, false, true)
              return item
            }
          }
          // 无数据兼容
          // item.data = item.data || 0
          return item
        },  `运营管理-企微好友消息推送`)
      } else {
        message.error('暂无数据')
      }
    } else {
      message.error('导出失败')
    }
  }).catch(() => {
    message.error('导出失败')
  })
}

// 导出充值明细
function touchDownloadRechargeDetails () {
  isLoading.value = true
  const params = {
    date: dateRange.value ? `${dateRange.value.format(dateFormat)}` : undefined,
    kw: search.value,
    create_user_id: creater.value,
    order: order.value,
    order_type: orderType.value,
    page: pagination.current,
    page_size: 20000
  }
  exportMessage(params).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0) {
      const list = data
      if (list.length) {
        const columns = [
          {
            name: '消息ID',
            field: 'message_id'
          },
          {
            name: '消息类型',
            field: 'message_type'
          },
          {
            name: '剧名',
            field: 'project_drama_name'
          },
          {
            name: '创建人',
            field: 'create_username'
          },
          {
            name: '推送时间',
            field: 'send_time'
          },
          {
            name: '购买时间',
            field: 'pay_success_time'
          },
          {
            name: 'UID',
            field: 'user_id'
          },
          {
            name: '推广ID',
            field: 'adv_id'
          },
          {
            name: '购买内容',
            field: 'charge_type'
          },
          {
            name: '金额',
            field: 'total_amount'
          },
          {
            name: '购买页',
            field: 'buy_place'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, (item, field, json, sheetIndex, row, col, rowCount, colCount) => {
          // row=0 为表头
          if (row != 0) {
            // 消息类型处理
            if (field === 'message_type') {
              item.data = (PushMsgTypes.find(item => item.value === json[field]) || {}).text || '-'
              return item
            } else if (field === 'total_amount') {
              item.data = Pub.KEEP_NUMBER_DECIMAL((json[field] || 0) / 100, 2, false, true)
              return item
            } else if (field === 'charge_type') {
              item.data = RechargeTypes.find(item => item.value === json[field]).text
              return item
            } else if (field === 'buy_place') {
              item.data = `${json[field]}${json.eq_number != 0 ? `【第${json.eq_number}集】` : ''}`
              return item
            }
          }
          // 无数据兼容
          // item.data = item.data || 0
          return item
        },  `运营管理-企微好友消息推送-充值明细`)
      } else {
        message.error('暂无数据')
      }
    } else {
      message.error('导出失败')
    }
  }).catch(() => {
    message.error('导出失败')
  })
}

</script>

<style scoped>
.alert-view {
  margin-top: 20px;
}
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

.break-span{
  word-break: break-all;
  word-wrap: break-word;
}
.gap {
  display: inline-block;
  color: #ccc;
  margin: 0 6px;
}
</style>